
import * as R from 'ramda';
import { delay } from 'redux-saga';
import { put, take, race } from 'redux-saga/effects';
// features
import { refreshTokenCatch, refreshTokenRequest, refreshTokenSuccess } from '../auth/actions';
// helpers/constants
import * as G from '../../helpers';
import { isProduction } from '../../helpers/env';
// feature sockets-v2
import * as C from './constants';
//////////////////////////////////////////////////

const getSocketURI = (token: string) => `${C.webSocketUri}?token=${token}`;

const parseDestination = (destination: string) => {
  const destinationsMap = R.toPairs(C.destinations);
  const pair = R.find(([, reg: RegExp]: Array) => reg.test(destination), destinationsMap);

  if (R.isNil(pair)) return {};

  const [serviceType, regexp] = pair;

  return {
    serviceType,
    actionPart: destination.replace(regexp, ''),
  };
};

const logMap = {
  [C.SOCKET_CLOSE]: 'CLOSED',
  [C.SOCKET_ERROR]: 'ERROR',
  [C.SOCKET_MESSAGE]: 'MESSAGE',
};

const bgColor = G.getTheme('colors.neroBlack');
const greenColor = G.getTheme('colors.dark.green');
const warningColor = G.getTheme('colors.warning');

const checkSocketDataErrors = (data: Object) => {
  const errorMessages = R.path(['payload', 'errorMessages'], data);

  if (G.isNilOrEmpty(errorMessages)) return;

  const messages = R.compose(
    R.map((str: string) => {
      if (G.isJson(str)) {
        const parsed = JSON.parse(str);

        if (G.isObject(parsed)) {
          return R.compose(
            R.head,
            R.values,
          )(parsed);
        }

        return parsed;
      }

      return str;
    }),
  )(errorMessages);

  G.handlePartialSuccessErrors(messages);
};

const logSocket = (type: string, data: Object) => {
  checkSocketDataErrors(data);

  if (isProduction) return;

  const typeText = R.prop(type, logMap);
  const logFn = G.ifElse(R.equals(type, C.SOCKET_ERROR), console.warn, console.log);
  const logColor = G.ifElse(R.equals(type, C.SOCKET_ERROR), warningColor, greenColor);
  const header = `%c======================== SOCKET ${typeText} ========================`;
  const logStyles = `background: ${bgColor}; color: ${logColor}; font-weight: bold; padding: 2px 12px;`;

  logFn(header, logStyles);
  logFn(data);
  logFn('%c'.padEnd(R.length(header), '='), logStyles);
};

let socketReconnects = 0;

const areSocketReconnectsOver = () => {
  socketReconnects += 1;

  if (R.gt(socketReconnects, C.MaxSocketReconnectTries)) {
    logSocket(C.SOCKET_ERROR, 'Can\'t reconnect websocket anymore');

    return true;
  }

  return false;
};

const resetSocketReconnectCounter = () => {
  socketReconnects = 0;
};

function* reconnectSocketSaga() {
  yield delay(C.SocketReconnectDelay);

  if (areSocketReconnectsOver()) return false;

  if (G.isAuthTokenExpired()) {
    yield put(refreshTokenRequest());

    yield race({
      success: take(refreshTokenSuccess),
      catchAction: take(refreshTokenCatch),
    });
  }

  return true;
}

export {
  logSocket,
  getSocketURI,
  parseDestination,
  reconnectSocketSaga,
  areSocketReconnectsOver,
  resetSocketReconnectCounter,
};
