import * as R from 'ramda';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import React, { useMemo, useState, useCallback } from 'react';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature toll-charges
import { assignDriverTruckRequest } from '../actions';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const UpdateDriverTruck = ({ truckOptions, driverOptions, initialValues }: Object) => {
  const dispatch = useDispatch();

  const [driversTrucks, setDriversTrucks] = useState({});

  const formik = useFormik({
    initialValues,
    onSubmit: (values: Object) => dispatch(assignDriverTruckRequest(values)),
  });

  const { handleSubmit, setFieldValue } = formik;

  const fieldSettings = useMemo(() => [
    {
      inputWrapperStyles,
      type: 'reactSelect',
      options: driverOptions,
      shouldCustomChange: true,
      fieldName: GC.FIELD_DRIVER_GUID,
      label: ['titles:driver', 'Driver'],
    },
    {
      inputWrapperStyles,
      type: 'reactSelect',
      options: truckOptions,
      fieldName: GC.FIELD_TRUCK_GUID,
      label: ['titles:truck', 'Truck'],
    },
  ], []);

  const handleCustomChange = useCallback(async (driverGuid: string) => {
    setFieldValue(GC.FIELD_DRIVER_GUID, driverGuid);

    if (R.isNil(driverGuid)) return;

    if (R.has(driverGuid, driversTrucks)) {
      return setFieldValue(GC.FIELD_TRUCK_GUID, R.prop(driverGuid, driversTrucks));
    }

    dispatch(openLoader());

    const res = await sendRequest('get', endpointsMap.getAssignInfoEndpoint(driverGuid));

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const truckGuid = R.pathOr(null, [GC.SYSTEM_OBJECT_TRUCK, GC.FIELD_GUID], data);

      setFieldValue(GC.FIELD_TRUCK_GUID, truckGuid);
      setDriversTrucks(R.assoc(driverGuid, truckGuid, driversTrucks));
    }

    dispatch(closeLoader());
  }, [driversTrucks]);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...formik}
        fields={fieldSettings}
        handleCustomChange={handleCustomChange}
        fieldsWrapperStyles={{ mt: 15, flexDirection: 'column' }}
      />
      <FormFooter2 />
    </form>
  );
};

export default UpdateDriverTruck;
